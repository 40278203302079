<template>
  <div
    v-show="showModal"
    class="layerCommon alarm-type"
    title="알림 메시지"
  >
    <div
      class="layer-popup-alarm"
    >
      <div class="title">
        <h2>알림</h2>
        <button
          class="btn-close"
          @click.prevent="$emit('close')"
        >
          닫기
        </button>
      </div>
      <div class="alarm-list">
        <div
          v-for="m in list"
          :key="m.id"
          class="alarm-item"
          :class="m.isRead ? 'after-type' : 'before-type'"
          style="cursor: pointer;"
        >
          <p @click.prevent="read(m)">
            {{ m.message }}
          </p>
          <button
            class="btn-list-delete"
            @click.prevent="remove(m.id)"
          >
            delete
          </button>
        </div>
        <div
          v-if="list.length === 0"
          class="alarm-item-empty"
        >
          알림 내용이 없습니다.
        </div>
      </div>
      <div class="bottom-fix">
        <div class="check-wrap">
          <input
            id="allRead"
            v-model="unReadMessage"
            type="checkbox"
          >
          <label
            for="allRead"
            style="cursor: pointer;"
            @click.prevent="readAll"
          >모두 읽음</label>
        </div>
        <button
          class="btn-all-delete"
          @click.prevent="removeAll"
        >
          전체 알림 삭제
        </button>
      </div>
    </div>
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      list: [],
      unReadMessage: false,
    }
  },
  computed: {
    ...mapGetters({
      params: 'params/getParams',
    }),
  },
  watch: {
    showModal(to) {
      if (to) {
        this.$emit("count")

        this.fetchNotifications()
      }
    },
  },
  methods: {
    fetchNotifications() {
      axios.get('/fu/notification/list')
        .then(rs => {
          this.list = rs.data

          this.checkUnReadMessage()
        })
        .catch(() => {
          this.list = []
        })
    },
    checkUnReadMessage() {
      // 읽지 않은 메시지 하나라도 존재 시 true
      this.unReadMessage = this.list.some(x => x.isRead === false)
    },
    read(item) {
      const target = item

      if (target.isRead) this.link(item.linkCategory, item.linkPayload)
      else {
        axios.post('/fu/notification/update-read-status', [target.id])
          .then(() => {
            target.isRead = true

            this.checkUnReadMessage()

            this.link(item.linkCategory, item.linkPayload)

            this.$emit("count")
          })
          .catch(() => {
          // 읽음 상태 변경 오류와 관계없이 링크 로직은 동작
            this.link(item.linkCategory, item.linkPayload)
          })
      }
    },
    link(category, payload) {
      if (category === 1) {
        this.$router.push({ name: 'app-point-mall-main' }).catch({})
      } else if (category === 2) {
        this.$store.commit('util/setParams', { route: 'app-mypage-askHistory', id: payload })

        if (this.$route.name !== 'app-mypage-askHistory') this.$router.push({ name: 'app-mypage-askHistory' }).catch({})
      } else if (category === 3) {
        this.$store.commit('util/setParams', { route: 'app-webseminar-my', id: payload, seminarType: 1 })

        if (this.$route.name !== 'app-webseminar-my') this.$router.push({ name: 'app-webseminar-my' }).catch({})
      } else if (category === 4) {
        this.$store.commit('util/setParams', { route: 'app-medical-trend-main', id: payload })

        if (this.$route.name !== 'app-medical-trend-main') this.$router.push({ name: 'app-medical-trend-main' }).catch({})
      } else if (category === 5) {
        this.$store.commit('util/setParams', { route: 'app-druginfo-main', id: payload })

        if (this.$route.name !== 'app-druginfo-main') this.$router.push({ name: 'app-druginfo-main' }).catch({})
      } else if (category === 6) {
        this.$store.commit('util/setParams', { route: 'app-survey-main', id: payload, surveyType: 2 })

        if (this.$route.name !== 'app-survey-main') this.$router.push({ name: 'app-survey-main' }).catch({})
      } else if (category === 7) {
        this.$store.commit('util/setParams', { route: 'app-notice-main', id: payload })

        if (this.$route.name !== 'app-notice-main') this.$router.push({ name: 'app-notice-main' }).catch({})
      } else if (category === 8) {
        this.$store.commit('util/setParams', { route: 'app-event-main', id: payload })

        if (this.$route.name !== 'app-event-main') this.$router.push({ name: 'app-event-main' }).catch({})
      }

      this.$emit('close')
    },
    readAll() {
      const unreads = this.list.filter(x => x.isRead === false)

      if (unreads.length > 0) {
        const unreadIds = unreads.map(x => x.id)

        axios.post('/fu/notification/update-read-status', unreadIds)
          .then(() => {
            for (let i = 0; i < unreads.length; i += 1) {
              unreads[i].isRead = true
            }

            this.unReadMessage = false

            this.$emit("count")
          })
      }
    },
    remove(id) {
      if (!id) {
        this.showAlertDialog('삭제할 알림이 없습니다.')
        return
      }
      axios.delete('/fu/notification/remove', { data: [id] })
        .then(() => {
          const targetIdx = this.list.findIndex(x => x.id === id)

          this.list.splice(targetIdx, 1)
        })
        .catch(() => {
          this.showAlertDialog('알림 삭제에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    removeAll() {
      if (!this.list || this.list.length === 0) {
        this.showAlertDialog('삭제할 알림이 없습니다.')

        return
      }
      this.showConfirmDialog('전체 알림을 삭제하시겠습니까?', result => {
        if (result) {
          const ids = this.list.map(x => x.id)

          axios.delete('/fu/notification/remove', { data: ids })
            .then(() => {
              this.list = []
            })
            .catch(() => {
              this.showAlertDialog('알림 삭제에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
            })
        }
      })
    },
  },
}
</script>

<template>
  <div
    v-show="showModal"
    class="layerCommon month-type"
    title="이번 달 참여 현황"
  >
    <div class="layer-popup-month-status">
      <h2>이 달의 미션</h2>
      <button
        class="btn-close"
        @click.prevent="$emit('close')"
      >
        닫기
      </button>
      <!-- 이 달의 미션 4줄 까지만 &nbsp;=> 살려주세요 -->
      <div class="month-mission-list">
        <ol>
          <!-- <li><a><span class="label-num">1</span>약품 정보&nbsp;<em>월 {{ drugReply.goal }}회 이상 댓글 작성 시</em>&nbsp;<span class="label-point">{{ drugReply.point | $numberFormatter }} P</span></a></li> -->
          <li v-if="webinarView !== null">
            <a><span class="label-num">1</span>제약 웹 세미나&nbsp;<em>월 {{ webinarView.goal }}회 이상 일정 등록 시</em>&nbsp;<span class="label-point">{{ webinarView.point | $numberFormatter }} P</span></a>
          </li>
          <li v-else>
            <a><span class="label-num">1</span>제약 웹 세미나&nbsp;<em>등록 된 미션정보가 없습니다.</em></a>
          </li>
          <li v-if="mediReply !== null">
            <a><span class="label-num">2</span>메디컬 트렌드&nbsp;<em>월 {{ mediReply.goal }}회 이상 댓글 작성 시</em>&nbsp;<span class="label-point">{{ mediReply.point | $numberFormatter }} P</span></a>
          </li>
          <li v-else>
            <a><span class="label-num">2</span>메디컬 트렌드&nbsp;<em>등록 된 미션정보가 없습니다.</em></a>
          </li>
          <li v-if="wChoice !== null">
            <a><span class="label-num">3</span>Weekly Choice&nbsp;<em>월 {{ wChoice.goal }}회 이상 참여 시</em>&nbsp;<span class="label-point">{{ wChoice.point | $numberFormatter }} P</span></a>
          </li>
          <li v-else>
            <a><span class="label-num">3</span>Weekly Choice&nbsp;<em>등록 된 미션정보가 없습니다.</em></a>
          </li>
        </ol>
      </div>

      <h2>참여 현황</h2>
      <div
        class="participation-status"
        style="min-height: initial;"
      >
        <!-- <div
          class="ps-item"
          :class="{ 'completion': drugReply.complete }"
        >
          <p class="title">
            약품 정보<span class="label">{{ drugReply.point | $numberFormatter }} P</span>
          </p>
          <div class="range-bar-wrap">
            <span class="label-range-title">댓글 {{ drugReply.goal }}개 작성</span>
            <span
              v-if="drugReply.complete"
              class="range-state"
            >완료</span>
            <span
              v-else
              class="range-state"
            >{{ drugReply.my }} <em>/</em> {{ drugReply.goal }}</span>

            <div class="range-bar">
              <div
                class="move-bar"
                :style="{ width: drugReply.rate + '%' }"
              />
            </div>
          </div>
        </div> -->
        <div
          v-if="webinarView !== null"
          class="ps-item"
          :class="{ 'completion': webinarView.complete }"
        >
          <p class="title">
            제약 웹 세미나<span class="label">{{ webinarView.point | $numberFormatter }} P</span>
          </p>
          <div class="range-bar-wrap">
            <span class="label-range-title">세미나 {{ webinarView.goal }}회 일정 등록</span>
            <span
              v-if="webinarView.complete"
              class="range-state"
            >완료</span>
            <span
              v-else
              class="range-state"
            >{{ webinarView.my }} / {{ webinarView.goal }}</span>

            <div class="range-bar">
              <div
                class="move-bar"
                :style="{ width: webinarView.rate + '%' }"
              />
            </div>
          </div>
        </div>
        <div
          v-if="mediReply !== null"
          class="ps-item"
          :class="{ 'completion': mediReply.complete }"
        >
          <p class="title">
            메디컬 트렌드<span class="label">{{ mediReply.point | $numberFormatter }} P</span>
          </p>
          <div class="range-bar-wrap">
            <span class="label-range-title">댓글 {{ mediReply.goal }}개 작성</span>
            <span
              v-if="mediReply.complete"
              class="range-state"
            >완료</span>
            <span
              v-else
              class="range-state"
            >{{ mediReply.my }} <em>/</em> {{ mediReply.goal }}</span>

            <div class="range-bar">
              <div
                class="move-bar"
                :style="{ width: mediReply.rate + '%' }"
              />
            </div>
          </div>
        </div>
        <div
          v-if="wChoice !== null"
          class="ps-item"
          :class="{ 'completion': wChoice.complete }"
        >
          <p class="title">
            Weekly Choice<span class="label">{{ wChoice.point | $numberFormatter }} P</span>
          </p>
          <div class="range-bar-wrap">
            <span class="label-range-title">Weekly Choice {{ wChoice.goal }}회 참여</span>
            <span
              v-if="wChoice.complete"
              class="range-state"
            >완료</span>
            <span
              v-else
              class="range-state"
            >{{ wChoice.my }} / {{ wChoice.goal }}</span>

            <div class="range-bar">
              <div
                class="move-bar"
                :style="{ width: wChoice.rate + '%' }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      missions: [],
      drugReply: {
        my: 0,
        goal: 0,
        point: 0,
        rate: 0,
        complete: false,
      },
      mediReply: {
        my: 0,
        goal: 0,
        point: 0,
        rate: 0,
        complete: false,
      },
      webinarView: {
        my: 0,
        goal: 0,
        point: 0,
        rate: 0,
        complete: false,
      },
      wChoice: {
        my: 0,
        goal: 0,
        point: 0,
        rate: 0,
        complete: false,
      },
    }
  },
  watch: {
    showModal(to) {
      if (to) {
        this.fetchMonthMission()
      }
    },
  },
  methods: {
    missionSetting(data, category) {
      const mission = data.find(x => x.category === category)
      if (mission == null) return null

      return {
        my: mission.count,
        goal: mission.goal,
        point: mission.point,
        rate: mission.complete ? 100 : (mission.count >= mission.goal ? 100 : Math.round((mission.count / mission.goal) * 100)),
        complete: mission.complete
      }
    },
    fetchMonthMission() {
      axios.get('/fu/point-history/mission')
        .then(rs => {
          // this.drugReply = this.missionSetting(rs.data, 12)
          this.mediReply = this.missionSetting(rs.data, 13)
          this.webinarView = this.missionSetting(rs.data, 14)
          this.wChoice = this.missionSetting(rs.data, 16)
        })
    },
  },
}
</script>

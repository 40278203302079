var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showModal,
          expression: "showModal",
        },
      ],
      staticClass: "layerCommon alarm-type",
      attrs: { title: "알림 메시지" },
    },
    [
      _c("div", { staticClass: "layer-popup-alarm" }, [
        _c("div", { staticClass: "title" }, [
          _c("h2", [_vm._v("알림")]),
          _c(
            "button",
            {
              staticClass: "btn-close",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" 닫기 ")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "alarm-list" },
          [
            _vm._l(_vm.list, function (m) {
              return _c(
                "div",
                {
                  key: m.id,
                  staticClass: "alarm-item",
                  class: m.isRead ? "after-type" : "before-type",
                  staticStyle: { cursor: "pointer" },
                },
                [
                  _c(
                    "p",
                    {
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.read(m)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(m.message) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn-list-delete",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.remove(m.id)
                        },
                      },
                    },
                    [_vm._v(" delete ")]
                  ),
                ]
              )
            }),
            _vm.list.length === 0
              ? _c("div", { staticClass: "alarm-item-empty" }, [
                  _vm._v(" 알림 내용이 없습니다. "),
                ])
              : _vm._e(),
          ],
          2
        ),
        _c("div", { staticClass: "bottom-fix" }, [
          _c("div", { staticClass: "check-wrap" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.unReadMessage,
                  expression: "unReadMessage",
                },
              ],
              attrs: { id: "allRead", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.unReadMessage)
                  ? _vm._i(_vm.unReadMessage, null) > -1
                  : _vm.unReadMessage,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.unReadMessage,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.unReadMessage = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.unReadMessage = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.unReadMessage = $$c
                  }
                },
              },
            }),
            _c(
              "label",
              {
                staticStyle: { cursor: "pointer" },
                attrs: { for: "allRead" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.readAll($event)
                  },
                },
              },
              [_vm._v("모두 읽음")]
            ),
          ]),
          _c(
            "button",
            {
              staticClass: "btn-all-delete",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.removeAll($event)
                },
              },
            },
            [_vm._v(" 전체 알림 삭제 ")]
          ),
        ]),
      ]),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="drPickWrap">
    <div class="title-bar">
      <h1>닥터인포 메인 레이아웃</h1>
    </div>
    <!-- 헤더, 메뉴 네비게이션 영역 -->
    <header-area />

    <section
      :class="{ 'main-wrap': wrapType === 'main', 'sub-wrap': wrapType === 'sub' }"
    >
      <router-view />
      <footer-area />
    </section>
  </div>
</template>

<script>
import HeaderArea from '../areas/HeaderArea.vue'
import FooterArea from '../areas/FooterArea.vue'

export default {
  components: {
    HeaderArea,
    FooterArea,
  },

  data() {
    return {
      wrapType: '',
    }
  },

  watch: {
    $route(to) {
      this.wrapType = to.meta.wrapType
    },
  },

  created() {
    this.wrapType = this.$route.meta.wrapType
  },
}
</script>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c("div", { staticClass: "footer-wrap" }, [
      _c("h1", [
        _c(
          "a",
          {
            attrs: { title: "홈" },
            on: {
              click: function ($event) {
                return _vm.link("app-main")
              },
            },
          },
          [_vm._v("UBCARE")]
        ),
      ]),
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.link("app-terms-service-term")
                },
              },
            },
            [_vm._v("이용약관")]
          ),
        ]),
        _c("li", { staticClass: "privacy-type" }, [
          _c(
            "a",
            {
              on: {
                click: function ($event) {
                  return _vm.link("app-terms-privacy-term")
                },
              },
            },
            [_vm._v("개인정보처리방침")]
          ),
        ]),
      ]),
      _c("p", { staticClass: "gray-logo" }, [_vm._v(" 닥터인포 ")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drPickWrap" },
    [
      _vm._m(0),
      _c("header-area"),
      _c(
        "section",
        {
          class: {
            "main-wrap": _vm.wrapType === "main",
            "sub-wrap": _vm.wrapType === "sub",
          },
        },
        [_c("router-view"), _c("footer-area")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-bar" }, [
      _c("h1", [_vm._v("닥터인포 메인 레이아웃")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <footer>
    <div class="footer-wrap">
      <h1><a
        title="홈"
        @click="link('app-main')"
      >UBCARE</a></h1>

      <ul>
        <li><a @click="link('app-terms-service-term')">이용약관</a></li>
        <li class="privacy-type">
          <a @click="link('app-terms-privacy-term')">개인정보처리방침</a>
        </li>
      </ul>

      <p class="gray-logo">
        닥터인포
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  methods: {
    link(route) {
      if (this.routeName !== route) {
        this.$router.push({ name: route }).catch(() => {})
      }
    },
  },
}
</script>

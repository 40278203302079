var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "comHeader",
      class: {
        "main-type": _vm.wrapType === "main",
        "sub-type": _vm.wrapType === "sub",
      },
    },
    [
      _c("header", { staticClass: "common-info" }, [
        _c("h1", [
          _c(
            "a",
            {
              attrs: { title: "홈" },
              on: {
                click: function ($event) {
                  return _vm.link("app-main")
                },
              },
            },
            [_vm._v("LOGO")]
          ),
        ]),
        _vm.isLogin
          ? _c(
              "div",
              {
                staticClass: "user-info",
                attrs: { "aria-label": "로그인성공" },
              },
              [
                _c("p", { staticClass: "welcome-txt" }, [
                  _c("span", [_vm._v(_vm._s(_vm.userName))]),
                  _vm._v(" 선생님 환영합니다. "),
                ]),
                _c("div", { staticClass: "user-point" }, [
                  _c("span", { staticClass: "label" }, [_vm._v("보유포인트")]),
                  _c("span", { staticClass: "point-num" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.link("app-point-mall-main")
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("$numberFormatter")(_vm.userPoint))
                        ),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "user-menu" }, [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "btn-myPage",
                          attrs: { title: "MY Page" },
                          on: {
                            click: function ($event) {
                              return _vm.link("app-mypage-profile")
                            },
                          },
                        },
                        [_vm._v("MY")]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "btn-alarm",
                          attrs: { id: "btnAlam", title: "알림" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openPopupAlarm($event)
                            },
                          },
                        },
                        [
                          _vm._v("알림"),
                          _c("span", { staticClass: "num" }, [
                            _vm._v(
                              _vm._s(
                                _vm.notificationCount > 99
                                  ? "99+"
                                  : _vm.notificationCount
                              )
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "btn-month-status",
                          attrs: {
                            id: "btnMonthStatus",
                            title: "이번 달 참여현황",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openPopupMonthAward($event)
                            },
                          },
                        },
                        [_vm._v("이번 달 참여현황")]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "btn-1-question",
                          attrs: { title: "1:1 문의" },
                          on: {
                            click: function ($event) {
                              return _vm.link("app-mypage-askHistory")
                            },
                          },
                        },
                        [_vm._v("1:1 문의")]
                      ),
                    ]),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "btn-logout",
                          attrs: { title: "로그아웃" },
                          on: { click: _vm.logout },
                        },
                        [_vm._v("로그아웃")]
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "global-common-layer" },
                    [
                      _c("popup-alarm", {
                        attrs: { "show-modal": _vm.showPopupAlarm },
                        on: {
                          close: _vm.closePopupAlarm,
                          count: _vm.fetchNotificationCount,
                        },
                      }),
                      _c("popup-month-award", {
                        attrs: { "show-modal": _vm.showPopupMonthAward },
                        on: { close: _vm.closePopupMonthAward },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            )
          : _vm._e(),
        !_vm.isLogin
          ? _c(
              "div",
              { staticClass: "user-info", attrs: { "aria-label": "로그아웃" } },
              [
                _c("div", { staticClass: "user-login" }, [
                  _c("ul", [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "btn-login",
                          on: {
                            click: function ($event) {
                              return _vm.link("login-main")
                            },
                          },
                        },
                        [_vm._v("로그인")]
                      ),
                    ]),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
      ]),
      _c("nav", { staticClass: "common-menu" }, [
        _c("ul", [
          _c(
            "li",
            { class: { active: _vm.routeName === "app-medical-trend-main" } },
            [
              _c(
                "a",
                {
                  attrs: { title: "메디컬 트렌드" },
                  on: {
                    click: function ($event) {
                      return _vm.link("app-medical-trend-main")
                    },
                  },
                },
                [_vm._v("메디컬 트렌드")]
              ),
            ]
          ),
          _c(
            "li",
            { class: { active: _vm.routeName === "app-druginfo-main" } },
            [
              _c(
                "a",
                {
                  attrs: { title: "약품 정보" },
                  on: {
                    click: function ($event) {
                      return _vm.link("app-druginfo-main")
                    },
                  },
                },
                [_vm._v("약품 정보")]
              ),
            ]
          ),
          _c(
            "li",
            {
              class: {
                active:
                  _vm.routeName === "app-webseminar-client" ||
                  _vm.routeName === "app-webseminar-kma" ||
                  _vm.routeName === "app-webseminar-my",
              },
            },
            [
              _c(
                "a",
                {
                  attrs: { title: "웹 심포지엄" },
                  on: {
                    click: function ($event) {
                      return _vm.link("app-webseminar-client")
                    },
                  },
                },
                [_vm._v("웹 심포지엄")]
              ),
            ]
          ),
          _c("li", { class: { active: _vm.routeName === "app-survey-main" } }, [
            _c(
              "a",
              {
                attrs: { title: "설문 조사" },
                on: {
                  click: function ($event) {
                    return _vm.link("app-survey-main")
                  },
                },
              },
              [_vm._v("설문 조사")]
            ),
          ]),
          _c("li", { class: { active: _vm.routeName === "app-event-main" } }, [
            _c(
              "a",
              {
                attrs: { title: "이벤트" },
                on: {
                  click: function ($event) {
                    return _vm.link("app-event-main")
                  },
                },
              },
              [
                _c("i", { staticClass: "event-badge-2412" }),
                _vm._v("이벤트"),
                _c("span", { staticClass: "event-badge-new" }, [_vm._v("NEW")]),
              ]
            ),
          ]),
          _c("li", { class: { active: _vm.routeName === "app-notice-main" } }, [
            _c(
              "a",
              {
                attrs: { title: "공지사항" },
                on: {
                  click: function ($event) {
                    return _vm.link("app-notice-main")
                  },
                },
              },
              [_vm._v("공지사항")]
            ),
          ]),
          _c(
            "li",
            { class: { active: _vm.routeName === "app-point-mall-main" } },
            [
              _c(
                "a",
                {
                  attrs: { title: "포인트몰" },
                  on: {
                    click: function ($event) {
                      return _vm.link("app-point-mall-main")
                    },
                  },
                },
                [_vm._v("포인트몰")]
              ),
            ]
          ),
        ]),
      ]),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }